
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";



const firebaseConfig = {
    apiKey: "AIzaSyDsB-k5bXPxzemr0sUxFVCff4OvJcnC_Gw",
    authDomain: "yaseenacademy-3b591.firebaseapp.com",
    projectId: "yaseenacademy-3b591",
    storageBucket: "yaseenacademy-3b591.appspot.com",
    messagingSenderId: "534080319243",
    appId: "1:534080319243:web:1cc206a89617d7a10ebc0e"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
