import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import OurProduct from "../components/OurProduct";
import ServicesBox from "../components/ServicesBox";
import ourservices from "../utils/Ourservices.json";
import ServicesBoxLeft from "../components/ServicesBoxLeft";
import image from "../Assets/book-5336298-1280-656adae8b717d.webp";
import image2 from "../Assets/quran-5385906-1280-656add8c5f7f6.webp";
import image3 from "../Assets/quran-4951034-1280-656add8d34fa2.webp";
import image4 from "../Assets/ramadhan-4219913_1280.jpg";
import { useNavigate } from "react-router-dom";
const ServicesPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Banner
        title={`Unlocking the Riches of Islamic Knowledge`}
        desc={
          "At Yasin Quran Academy, our courses are meticulously crafted to be more than just lessons — they are transformative experiences."
        }
        display={"none"}
        screen={"services"}
      />
      <ServicesBox
        secondHeading={"Noorani Qaida"}
        desc={
          "At Yasin Quran Academy, our educational journey begins with the foundational masterpiece — the Noorani Qaida. This essential tool is carefully curated to elevate your Arabic language skills, laying a robust foundation for your Quranic exploration.Immerse yourself in the art of precise pronunciation. Our expert instructors ensure that every phonetic nuance is grasped with clarity.Progress seamlessly towards fluency in Quranic recitation. The Noorani Qaida acts as a stepping stone towards a melodious and confident recitation of the Holy Quran."
        }
        image={image}
      />
      <ServicesBoxLeft
        secondHeading={" Quranic Recitation Mastery"}
        desc={
          "Embark on a profound journey into the art of Quranic recitation with our specialized course in Quranic Recitation Mastery. At Yasin Quran Academy, we understand that reciting the Quran is not merely a skill; it's an immersive experience that requires precision and a connection to the soul.Our curriculum delves into the intricate nuances of each Quranic verse. Through a careful study of tajweed rules, we guide you to understand the rhythm and melody inherent in the divine words."
        }
        image={image2}
      />
      <ServicesBox
        secondHeading={"Quran Memorization"}
        desc={
          "Embark on a transformative journey of Quran memorization with our specialized course designed to unlock the spiritual and intellectual benefits of Hifz. At Yasin Quran Academy, we recognize the profound significance of committing the Quran to memory, and our comprehensive program is crafted to make this sacred endeavor accessible and enriching"
        }
        image={image3}
      />
      <ServicesBoxLeft
        secondHeading={"Application in the Modern World"}
        desc={
          " Learn not just about Islam but how to live Islam in the modern world. Our courses emphasize the practical application of Islamic teachings in your daily routines, fostering a harmonious integration of faith in today's fast-paced life."
        }
        image={image4}
      />
      {/* <OurProduct title={"What we Do?"} OurServices={ourservices} /> */}

      <div className="footer-section">
        <h3>Sounds Great?! Book Your Demo Class!</h3>
        <span
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Contact Us
        </span>
      </div>
    </>
  );
};

export default ServicesPage;
