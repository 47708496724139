import React from "react";
import Navbar from "../components/Navbar";
import ContactusScreen from "../screen/ContactusScreen";

const ContactUsPage = () => {
  return (
    <>
      <Navbar />
      <ContactusScreen />
    </>
  );
};

export default ContactUsPage;
