import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import AboutUs from "../components/AboutUs";
import OurServices from "../components/OurServices";
import OurProduct from "../components/OurProduct";
import ContactusScreen from "../screen/ContactusScreen";
import Ayatoftheday from "../components/Ayatoftheday";
import OurTeam from "../components/OurTeam";
import Tesmonail from "../components/Tesmonail";
import ourservices from "../utils/Ourservices.json";
import img1 from "../Assets/online-tajweed-course-656adaa6bb2e2.webp";
import img2 from "../Assets/online-tafsir-course-656adaa2f3f3d.webp";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <Banner
        title={`Welcome to Yasin Quran Academy`}
        desc={
          "We've been dedicated to spreading the light of Islamic knowledge. Our mission extends far beyond mere recitation; we are committed to illuminating the hearts and minds of our students, regardless of their age, with the profound teachings of the Quran and Hadees."
        }
        display={"initial"}
        screen={"home"}
      />
      <AboutUs
        title={"Who we are?"}
        secondHeading={"We are your path to enlightened Islamic knowledge"}
        desc={
          "Yasin Quran Academy is more than just a Quran school. At Yasin Quran Academy, we take pride in our commitment to spreading Islamic knowledge and fostering a deep understanding of the Quran. Our mission is rooted in the belief that the Quran is a timeless guide for humanity, and through dedicated efforts, we aim to facilitate Quranic education in various dimensions."
        }
        display={""}
        img={img1}
      />
      <OurServices
        title={"What do Student Gain?"}
        secondHeading={"Beyond Learning: A Journey to Understand"}
        desc={
          "At Yasin Quran Academy, we believe it's not just about learning; it's about understanding and living the teachings of the Quran in the modern world. Our courses go beyond the recitation of verses; we explore how to apply Islamic principles in our daily lives."
        }
        display={""}
        img={img2}
      />
      <OurProduct title={"Our COURSES"} OurServices={ourservices} />
      <Tesmonail />
      <OurTeam />
      <Ayatoftheday />
      <ContactusScreen />
    </>
  );
};

export default HomePage;
