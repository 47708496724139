import React from "react";
import { Container } from "react-bootstrap";
const Ayatoftheday = () => {
  return (
    <>
      <section
        className="ayaut-section"
        style={{ backgroundColor: "#252959", padding: "20px" }}
      >
        <Container>
          <h2
            className="main-heading"
            style={{ color: "#ffff", textAlign: "center" }}
          >
            Ayat of the day!!
          </h2>
          <p style={{ color: "#ffff", textAlign: "center" }}>
            “In the Name of your Lord Who has created (all that exists). He
            created man from a clot (a piece of thick coagulated blood). And
            your Lord is the Most Generous. Who has taught (the writing) by the
            pen? He has taught man that which he knew not”
          </p>
        </Container>
      </section>
    </>
  );
};

export default Ayatoftheday;
