import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import AboutUs from "../components/AboutUs";
import OurServices from "../components/OurServices";
import LatestWork from "../components/LatestWork";
import OurWork from "../utils/OurWork.json";
import img1 from "../Assets/quran-6114880-1280-656add9003ec8.webp";
import img2 from "../Assets/quran-2478729-1280-656add891ac2f.webp";
import { useNavigate } from "react-router-dom";

const AboutUsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Banner
        title={"Yasin Quran Academy"}
        desc={
          "Your top destination for Quran education. Whether you're starting to read the Quran or focusing on memorization, we offer comprehensive tutoring services covering all aspects of the Quran"
        }
        display={"none"}
        screen={"about"}
      />
      <AboutUs
        title={"Learning Quran online"}
        secondHeading={" WHY to learn ONLINE?"}
        desc={
          "Embark on your Quranic journey hassle-free with Yasin Quran Academy. Learn and read the Quran from the safety and comfort of your home, avoiding the uncertainties of external Quran schools. Our platform supports you in establishing a daily learning habit, ensuring you achieve your Quranic goals effectively and securely."
        }
        display={"none"}
        img={img1}
      />
      <OurServices
        title={"Why Yasin Quran Academy"}
        secondHeading={"Why choose us?"}
        desc={
          "At Yasin Quran Academy, we understand the immense responsibility of Quran education, recognizing the challenge of finding a trustworthy platform. We empathize with your concerns and, having been in your shoes, assure you that we prioritize top-quality classes. Our highly qualified Quran teachers are dedicated to providing a comfortable and satisfying learning experience. Trust Yasin Quran Academy as your reliable Quran learning platform"
        }
        display={"none"}
        img={img2}
      />
      <LatestWork title={"We are committed"} json={OurWork} />
      <div className="footer-section">
        <h3>
          We schedule classes at your convenience. We have male and female Quran
          tutors available 24/7
        </h3>
        <span
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Contact Us
        </span>
      </div>
    </>
  );
};

export default AboutUsPage;
