import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "../styles/ContactUs.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillInstagram,
} from "react-icons/ai";
// import Button from "../common/Button";
import shape2 from "../Assets/Shape2.webp";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { serverTimestamp } from "firebase/firestore";
import { db } from "../utils/firebase";
import { addDoc, collection } from "@firebase/firestore";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const SignupSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Username should be alphabet")
    .required("Name Required"),
  lastname: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Username should be alphabet")
    .required("Name Required"),
  email: Yup.string()
    .email()
    .matches(/^(?!.*@[^,]*,)/)
    .required("Email Required"),
  number: Yup.string()
    .matches(/^(?:\+|\d{1,4}\s?)(?:\d{1,})$/, "Start with +")
    .required("Number Required"),
  country: Yup.string().required("Country Required"),
  // dateTime: Yup.date().required("Date and time are required"),
});

const ContactUsFrom = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    number: "",
    country: "",
    // dateTime: null,
  };
  const onSubmit = (values) => {
    setLoader(true);
    handleValues(
      values.firstname,
      values.lastname,
      values.email,
      values.country,
      values.number
      // values.dateTime
    );
  };

  const handleValues = async (
    firstname,
    lastname,
    email,
    country,
    number
    // dateTime
  ) => {
    let data = {
      firstname,
      lastname,
      email,
      country,
      number,
      // dateTime,
      createdAt: serverTimestamp(),
    };
    const ref = collection(db, "userFrom");
    let resp = await addDoc(ref, data);
    setLoader(false);

    Swal.fire({
      title: "We Hear Your Call",
      text: "We will get back to you soon!",
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        const initialValues = {
          firstname: "",
          lastname: "",
          email: "",
          number: "",
          country: "",
          dateTime: null,
        };
        navigate("/aboutus");
      }
    });
  };
  const openWhatsapp = () => {
    const whatsappNumber = "+923205047810";
    const whatsappLink = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappLink, "_blank");
  };

  const openGmail = () => {
    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      "admin@yasinquranacademy.com"
    )}&subject=${encodeURIComponent}`;
    window.open(gmailComposeUrl, "_blank");
  };

  return (
    <>
      <section className="contactus">
        <Container>
          <div className="form">
            <div className="form-body">
              <h2 style={{ color: "#454654" }}>As-salamu alaykum</h2>
              <p>We’d like to talk with you.</p>
              <Formik
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <span className="lable-span">First Name</span>
                    <Field
                      type="text"
                      id="firstname"
                      name="firstname"
                      className="contactus-input"
                      placeholder="First Name"
                    />
                    <div className="errMsg">{errors.firstname}</div>

                    <span className="lable-span">Last Name</span>
                    <Field
                      type="text"
                      id="lastname"
                      name="lastname"
                      className="contactus-input"
                      placeholder="Last Name"
                    />
                    <div className="errMsg">{errors.lastname}</div>

                    <span className="lable-span">Email</span>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className="contactus-input"
                      placeholder="Email"
                    />
                    <div className="errMsg">{errors.email}</div>
                    <span className="lable-span">Number</span>
                    <Field
                      type="tel"
                      id="number"
                      name="number"
                      className="contactus-input"
                      placeholder="Number"
                    />
                    <div className="errMsg">{errors.number}</div>
                    <span className="lable-span">Country</span>
                    <Field
                      className="contactus-input"
                      as="select"
                      id="country"
                      name="country"
                    >
                      <option>select country</option>
                      <option value="AF">Afghanistan</option>
                      <option value="AX">Aland Islands</option>
                      <option value="AL">Albania</option>
                      <option value="DZ">Algeria</option>
                      <option value="AS">American Samoa</option>
                      <option value="AD">Andorra</option>
                      <option value="AO">Angola</option>
                      <option value="AI">Anguilla</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AR">Argentina</option>
                      <option value="AM">Armenia</option>
                      <option value="AW">Aruba</option>
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BS">Bahamas</option>
                      <option value="BH">Bahrain</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BB">Barbados</option>
                      <option value="BY">Belarus</option>
                      <option value="BE">Belgium</option>
                      <option value="BZ">Belize</option>
                      <option value="BJ">Benin</option>
                      <option value="BM">Bermuda</option>
                      <option value="BT">Bhutan</option>
                      <option value="BO">Bolivia</option>
                      <option value="BQ">
                        Bonaire, Sint Eustatius and Saba
                      </option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BW">Botswana</option>
                      <option value="BV">Bouvet Island</option>
                      <option value="BR">Brazil</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="BN">Brunei Darussalam</option>
                      <option value="BG">Bulgaria</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BI">Burundi</option>
                      <option value="KH">Cambodia</option>
                      <option value="CM">Cameroon</option>
                      <option value="CA">Canada</option>
                      <option value="CV">Cape Verde</option>
                      <option value="KY">Cayman Islands</option>
                      <option value="CF">Central African Republic</option>
                      <option value="TD">Chad</option>
                      <option value="CL">Chile</option>
                      <option value="CN">China</option>
                      <option value="CX">Christmas Island</option>
                      <option value="CC">Cocos (Keeling) Islands</option>
                      <option value="CO">Colombia</option>
                      <option value="KM">Comoros</option>
                      <option value="CG">Congo</option>
                      <option value="CD">
                        Congo, Democratic Republic of the Congo
                      </option>
                      <option value="CK">Cook Islands</option>
                      <option value="CR">Costa Rica</option>
                      <option value="CI">Cote D'Ivoire</option>
                      <option value="HR">Croatia</option>
                      <option value="CU">Cuba</option>
                      <option value="CW">Curacao</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DK">Denmark</option>
                      <option value="DJ">Djibouti</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="EC">Ecuador</option>
                      <option value="EG">Egypt</option>
                      <option value="SV">El Salvador</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="ER">Eritrea</option>
                      <option value="EE">Estonia</option>
                      <option value="ET">Ethiopia</option>
                      <option value="FK">Falkland Islands (Malvinas)</option>
                      <option value="FO">Faroe Islands</option>
                      <option value="FJ">Fiji</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GF">French Guiana</option>
                      <option value="PF">French Polynesia</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="GA">Gabon</option>
                      <option value="GM">Gambia</option>
                      <option value="GE">Georgia</option>
                      <option value="DE">Germany</option>
                      <option value="GH">Ghana</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GR">Greece</option>
                      <option value="GL">Greenland</option>
                      <option value="GD">Grenada</option>
                      <option value="GP">Guadeloupe</option>
                      <option value="GU">Guam</option>
                      <option value="GT">Guatemala</option>
                      <option value="GG">Guernsey</option>
                      <option value="GN">Guinea</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="GY">Guyana</option>
                      <option value="HT">Haiti</option>
                      <option value="HM">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="VA">Holy See (Vatican City State)</option>
                      <option value="HN">Honduras</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HU">Hungary</option>
                      <option value="IS">Iceland</option>
                      <option value="IN">India</option>
                      <option value="ID">Indonesia</option>
                      <option value="IR">Iran, Islamic Republic of</option>
                      <option value="IQ">Iraq</option>
                      <option value="IE">Ireland</option>
                      <option value="IM">Isle of Man</option>
                      <option value="IL">Israel</option>
                      <option value="IT">Italy</option>
                      <option value="JM">Jamaica</option>
                      <option value="JP">Japan</option>
                      <option value="JE">Jersey</option>
                      <option value="JO">Jordan</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="KE">Kenya</option>
                      <option value="KI">Kiribati</option>
                      <option value="KP">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="KR">Korea, Republic of</option>
                      <option value="XK">Kosovo</option>
                      <option value="KW">Kuwait</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="LA">
                        Lao People's Democratic Republic
                      </option>
                      <option value="LV">Latvia</option>
                      <option value="LB">Lebanon</option>
                      <option value="LS">Lesotho</option>
                      <option value="LR">Liberia</option>
                      <option value="LY">Libyan Arab Jamahiriya</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MO">Macao</option>
                      <option value="MK">
                        Macedonia, the Former Yugoslav Republic of
                      </option>
                      <option value="MG">Madagascar</option>
                      <option value="MW">Malawi</option>
                      <option value="MY">Malaysia</option>
                      <option value="MV">Maldives</option>
                      <option value="ML">Mali</option>
                      <option value="MT">Malta</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="MQ">Martinique</option>
                      <option value="MR">Mauritania</option>
                      <option value="MU">Mauritius</option>
                      <option value="YT">Mayotte</option>
                      <option value="MX">Mexico</option>
                      <option value="FM">
                        Micronesia, Federated States of
                      </option>
                      <option value="MD">Moldova, Republic of</option>
                      <option value="MC">Monaco</option>
                      <option value="MN">Mongolia</option>
                      <option value="ME">Montenegro</option>
                      <option value="MS">Montserrat</option>
                      <option value="MA">Morocco</option>
                      <option value="MZ">Mozambique</option>
                      <option value="MM">Myanmar</option>
                      <option value="NA">Namibia</option>
                      <option value="NR">Nauru</option>
                      <option value="NP">Nepal</option>
                      <option value="NL">Netherlands</option>
                      <option value="AN">Netherlands Antilles</option>
                      <option value="NC">New Caledonia</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NI">Nicaragua</option>
                      <option value="NE">Niger</option>
                      <option value="NG">Nigeria</option>
                      <option value="NU">Niue</option>
                      <option value="NF">Norfolk Island</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="NO">Norway</option>
                      <option value="OM">Oman</option>
                      <option value="PK">Pakistan</option>
                      <option value="PW">Palau</option>
                      <option value="PS">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="PA">Panama</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="PY">Paraguay</option>
                      <option value="PE">Peru</option>
                      <option value="PH">Philippines</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="QA">Qatar</option>
                      <option value="RE">Reunion</option>
                      <option value="RO">Romania</option>
                      <option value="RU">Russian Federation</option>
                      <option value="RW">Rwanda</option>
                      <option value="BL">Saint Barthelemy</option>
                      <option value="SH">Saint Helena</option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="MF">Saint Martin</option>
                      <option value="PM">Saint Pierre and Miquelon</option>
                      <option value="VC">
                        Saint Vincent and the Grenadines
                      </option>
                      <option value="WS">Samoa</option>
                      <option value="SM">San Marino</option>
                      <option value="ST">Sao Tome and Principe</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SN">Senegal</option>
                      <option value="RS">Serbia</option>
                      <option value="CS">Serbia and Montenegro</option>
                      <option value="SC">Seychelles</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SG">Singapore</option>
                      <option value="SX">Sint Maarten</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="SO">Somalia</option>
                      <option value="ZA">South Africa</option>
                      <option value="GS">
                        South Georgia and the South Sandwich Islands
                      </option>
                      <option value="SS">South Sudan</option>
                      <option value="ES">Spain</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="SD">Sudan</option>
                      <option value="SR">Suriname</option>
                      <option value="SJ">Svalbard and Jan Mayen</option>
                      <option value="SZ">Swaziland</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="SY">Syrian Arab Republic</option>
                      <option value="TW">Taiwan, Province of China</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TZ">Tanzania, United Republic of</option>
                      <option value="TH">Thailand</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TG">Togo</option>
                      <option value="TK">Tokelau</option>
                      <option value="TO">Tonga</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="TN">Tunisia</option>
                      <option value="TR">Turkey</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="TC">Turks and Caicos Islands</option>
                      <option value="TV">Tuvalu</option>
                      <option value="UG">Uganda</option>
                      <option value="UA">Ukraine</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="GB">United Kingdom</option>
                      <option value="US">United States</option>
                      <option value="UM">
                        United States Minor Outlying Islands
                      </option>
                      <option value="UY">Uruguay</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VU">Vanuatu</option>
                      <option value="VE">Venezuela</option>
                      <option value="VN">Viet Nam</option>
                      <option value="VG">Virgin Islands, British</option>
                      <option value="VI">Virgin Islands, U.s.</option>
                      <option value="WF">Wallis and Futuna</option>
                      <option value="EH">Western Sahara</option>
                      <option value="YE">Yemen</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                    </Field>
                    <div className="errMsg">{errors.country}</div>
                    {/* <span className="lable-span" style={{ display: "block" }}>
                      Demo Class Date and Time
                    </span>
                    <DatePicker
                      selected={values.dateTime}
                      onChange={(dateTime) =>
                        setFieldValue("dateTime", dateTime)
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="yyyy-MM-dd h:mm aa"
                      placeholderText="Select date and time"
                      className="contactus-input"
                    />
                    <div className="errMsg">{errors.dateTime}</div> */}
                    {loader ? (
                      <div class="loader" />
                    ) : (
                      <button className="btn" type="submit" onClick={() => {}}>
                        Send Message
                      </button>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
            <div className="contact-info">
              <div className="contact-info-body">
                <h2>Contact Information </h2>
                <p>
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </p>{" "}
                <p onClick={openWhatsapp} style={{ cursor: "pointer" }}>
                  <BsFillTelephoneFill /> <span>+92 3205047810</span>
                </p>
                <p>
                  <BsFillTelephoneFill /> <span>051 514933</span>
                </p>
                <p>
                  <MdEmail />{" "}
                  <span onClick={openGmail} style={{ cursor: "pointer" }}>
                    admin@yasinquranacademy.com
                  </span>
                </p>
                {/* <p style={{ marginBottom: " 20px" }}>
                  <ImLocation /> <span>demo location</span>
                </p> */}
                <div className="social-link">
                  <p>
                    <AiFillFacebook />
                  </p>
                  {/*<p>*/}
                  {/*  <AiOutlineTwitter />*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*  <AiFillLinkedin />*/}
                  {/*</p>*/}
                  <p>
                    <AiFillInstagram />
                  </p>
                </div>
              </div>
              <img src={shape2} alt="" />
              <div className="overlay"></div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUsFrom;
