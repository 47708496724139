import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import ServicesBox from "../components/ServicesBox";
import ServicesBoxLeft from "../components/ServicesBoxLeft";
import image from "../Assets/our-mission-656adaaba8d41.webp";
import image2 from "../Assets/our-mission2.jpg";
import image3 from "../Assets/book-5336298-1280-656adae8b717d.webp";
import { useNavigate } from "react-router-dom";

const PortfolioPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Banner
        title={`Our Mission`}
        desc={
          "At Yasin Quran Academy, we believe it's not just about learning; it's about understanding and living the teachings of the Quran in the modern world."
        }
        display={"none"}
        screen={"home"}
      />
      <ServicesBox
        secondHeading={"Learing with purpose"}
        desc={
          "At Yasin Quran Academy, we believe it's not just about learning; it's about understanding and living the teachings of the Quran in the modern world.  Our courses go beyond the recitation of verses; we explore At Yasin Quran Academy, we are not just an online Quran school; we are a passionate team on a mission to spread Islamic knowledge and enhance your understanding of the Quran in a profound way. "
        }
        image={image}
      />
      <ServicesBoxLeft
        secondHeading={"Social Responsibility"}
        desc={
          "We believe in the transformative power of knowledge to bring about positive societal change. Our mission includes nurturing a sense of social responsibility in our students, motivating them to actively contribute to the betterment of society. Our vision is to create ambassadors of Islam—individuals who not only embody the principles of Islam in their daily lives but also actively work to dispel misconceptions and promote a positive image of the faith."
        }
        image={image3}
      />
      <ServicesBox
        secondHeading={"Holistic Islamic Knowledge"}
        desc={
          " We strive to provide a well-rounded education, ensuring our students develop a firm grip on Islamic knowledge. From Quranic studies to Hadees and practical applications, our courses are designed to instill a comprehensive understanding."
        }
        image={image2}
      />
      {/* <ServicesBox
        title={"We are Solution Providers"}
        secondHeading={
          " Crafting Tailored Solutions for Your Unique Challenges"
        }
        desc={
          "At DevCosmic, we understand that every business is unique. That's why we go beyond generic solutions, offering personalized strategies and architectures that align with your specific needs. Our commitment is to provide comprehensive solutions, ensuring that every line of code serves the purpose of enhancing your business objectives. With DevCosmic, your digital transformation is in expert hands."
        }
        image={image4}
      /> */}
      {/* <ServicesBoxLeft
        title={"We are Solution Providers"}
        secondHeading={
          " Crafting Tailored Solutions for Your Unique Challenges"
        }
        desc={
          "At DevCosmic, we understand that every business is unique. That's why we go beyond generic solutions, offering personalized strategies and architectures that align with your specific needs. Our commitment is to provide comprehensive solutions, ensuring that every line of code serves the purpose of enhancing your business objectives. With DevCosmic, your digital transformation is in expert hands."
        }
        image={image5}
      /> */}
      {/* <ServicesBox
        title={"We are Solution Providers"}
        secondHeading={
          " Crafting Tailored Solutions for Your Unique Challenges"
        }
        desc={
          "At DevCosmic, we understand that every business is unique. That's why we go beyond generic solutions, offering personalized strategies and architectures that align with your specific needs. Our commitment is to provide comprehensive solutions, ensuring that every line of code serves the purpose of enhancing your business objectives. With DevCosmic, your digital transformation is in expert hands."
        }
        image={image6}
      /> */}
      {/* <OurProduct title={"What we Do?"} OurServices={ourservices} /> */}

      <div className="footer-section">
        <h3>Book Your Demo Class</h3>
        <span
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Contact Us
        </span>
      </div>
    </>
  );
};

export default PortfolioPage;
