import React from "react";
import "../styles/Banner.css";
import shape from "../Assets/Shape.webp";
import Button from "../common/Button";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import img from "../Assets/Whatsapp-icon-Royalty-Free-Vector-Image-VectorStock.png";

const Banner = (props) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/contactus");
  };
  const openWhatsapp = () => {
    const whatsappNumber = "+923205047810";
    const whatsappLink = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <>
      <div
        className={
          props.screen === "home"
            ? "banner"
            : props.screen === "about"
            ? "about-banner"
            : "services-banner"
        }
      >
        <Container>
          <div className="banner-content">
            <h2 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              {props.title}
            </h2>
            <p>{props.desc}</p>
            <div
              style={{
                maxWidth: "50%",
                margin: "0 auto",
                display: `${props.display}`,
              }}
            >
              <Button title={"Get Started"} clickFunction={handleNavigation} />
            </div>
          </div>
        </Container>
        <img src={shape} alt="shape" className="shape-img" />
        <div class="overlay"></div>
        <img
          src={img}
          alt="shape"
          className="whatsappimg"
          onClick={openWhatsapp}
        />
      </div>
    </>
  );
};

export default Banner;
